import {
  HttpError,
  IResourceComponentsProps,
  useApiUrl,
  useGetIdentity,
  useList,
} from '@pankod/refine-core';
import React from 'react';

import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { IHistory } from 'interfaces/common';
import { Box, Stack } from '@pankod/refine-mui';
import { ReactComponent as BlueBin } from '../assets/icons/blue_bin.svg';
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
    sx={{
      marginBottom: 1,
    }}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    // expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#FFF',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),

  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export const History: React.FC<IResourceComponentsProps> = () => {
  const { data: user } = useGetIdentity();
  const driverId = user && user.driver.id;

  const { data } = useList<IHistory, HttpError>({
    resource: `historiques/driver/${driverId}`,
  });
  const history = data?.data ?? [];

  const [expanded, setExpanded] = React.useState<string | false>('panel1');

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  return (
    <>
      <Box
        component="main"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          // justifyContent: 'center',

          height: '70vh',
          mt: '12px',
          overflow: 'scroll',
          width: '100%',
        }}
      >
        <Box>
          {[...history]?.reverse().map((h) => (
            <Accordion
              key={h.id}
              expanded={expanded === `${h.id}`}
              onChange={handleChange(`${h.id}`)}
            >
              <AccordionSummary
                aria-controls={`${h.id}-content`}
                id={`${h.id}-header`}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between
                "
                  sx={{ width: '100%' }}
                >
                  <Stack direction="row" spacing={1} alignItems="center">
                    {h.etat === 'sansRapport' ? (
                      <BlueBin width={20} />
                    ) : (
                      <GppMaybeIcon sx={{ color: 'error.main' }} />
                    )}

                    <Stack>
                      <Typography
                        fontFamily="Inter"
                        fontSize={12}
                        sx={{
                          color:
                            h.etat === 'sansRapport'
                              ? 'primary.main'
                              : 'error.main',
                        }}
                      >
                        SRB #{h.srb}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="column" spacing={2} alignItems="center">
                    <Stack>
                      <Typography
                        fontFamily="Inter"
                        fontSize={12}
                        sx={{ color: '#000' }}
                      >
                        POIDS ({h?.poid} KG)
                      </Typography>
                      {/* <Typography fontSize={12} sx={{ color: '#000' }}>
                        {h?.createdAt && h?.createdAt.substring(0, 10)}
                      </Typography> */}
                      {h?.createdAt && (
                        <Typography
                          fontFamily="Inter"
                          fontSize={12}
                          sx={{ color: '#000' }}
                        >
                          {new Date(h.createdAt).getDate()}{' '}
                          {new Date(h.createdAt).toLocaleString('default', {
                            month: 'short',
                          })}{' '}
                          {new Date(h.createdAt).getFullYear()}{' '}
                          {new Date(h.createdAt).getHours()}:
                          {new Date(h.createdAt).getMinutes()}
                        </Typography>
                      )}
                    </Stack>
                  </Stack>
                </Stack>
              </AccordionSummary>
              {h.etat === 'rapport' && (
                <AccordionDetails>
                  <Typography
                    fontFamily="Inter"
                    fontSize={12}
                    sx={{ color: '#000' }}
                  >
                    {h.probleme ? h.probleme : h.note}
                  </Typography>
                </AccordionDetails>
              )}
            </Accordion>
          ))}
        </Box>
      </Box>
    </>
  );
};
