import React from 'react';

import { Refine, AuthProvider } from '@pankod/refine-core';
import {
  notificationProvider,
  RefineSnackbarProvider,
  CssBaseline,
  GlobalStyles,
  ThemeProvider,
  ReadyPage,
  ErrorComponent,
} from '@pankod/refine-mui';
import { customTheme } from './theme';

import dataProvider from '@pankod/refine-simple-rest';

import routerProvider from '@pankod/refine-react-router-v6';
import { authProvider } from './authProvider';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { Title, Layout, Header } from 'components/layout';
import { Login } from 'pages/login';
import { MainScreen } from 'pages/MainScreen';
import { Bin } from 'pages/Bin';

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };
  const axiosInstance = axios.create();
  axiosInstance.defaults.withCredentials = true;
  return (
    <ThemeProvider theme={customTheme}>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: 'auto' } }} />
      <RefineSnackbarProvider>
        <Refine
          dataProvider={dataProvider(
            'https://driver.polyrec-app.com/api',
            axiosInstance
          )}
          notificationProvider={notificationProvider}
          ReadyPage={ReadyPage}
          catchAll={<ErrorComponent />}
          resources={[
            {
              name: 'requests',
              list: MainScreen,
              create: Bin,
            },
          ]}
          Title={Title}
          Layout={Layout}
          Header={Header}
          routerProvider={routerProvider}
          authProvider={authProvider}
          LoginPage={Login}
          i18nProvider={i18nProvider}
        />
      </RefineSnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
