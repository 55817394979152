import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@pankod/refine-mui';
import React, { useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import logo_polyrec from '../assets/logo_polyrec.svg';
import logo_kb from '../assets/logo_kb.svg';
import background_mobile from '../assets/background_mobile.svg';
import { useLogin } from '@pankod/refine-core';
export const Login: React.FC = () => {
  //
  const { mutate: login } = useLogin();
  const [states, setStates] = useState({});
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setStates({ ...states, [event?.target?.name]: event?.target?.value });
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    login({ ...states });
  };
  //
  const [showPassword, setshowPassword] = useState<boolean>(false);
  const handleClickShowPassword = () => setshowPassword((show) => !show);
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  const [currentView, setCurrentView] = useState('SpalshScreen');
  const [elapsedTime, setElapsedTime] = useState(0);

  // Set the elapsed time to 0 when the component mounts
  React.useEffect(() => {
    setElapsedTime(0);
  }, []);

  // Update the elapsed time every second
  React.useEffect(() => {
    const timer = setInterval(() => {
      setElapsedTime((prevElapsedTime) => prevElapsedTime + 1);
    }, 1000);

    // Clean up the timer when the component unmounts
    return () => {
      clearInterval(timer);
    };
  }, []);

  // Switch to the MainScreen after 5 seconds
  React.useEffect(() => {
    if (elapsedTime >= 5) {
      setCurrentView('MainScreen');
    }
  }, [elapsedTime]);

  // SplashScreen
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 25
      );
    }, 800);

    return () => {
      clearInterval(timer);
    };
  }, []);
  const renderView = () => {
    switch (currentView) {
      case 'SpalshScreen':
        return (
          <Box
            component="div"
            sx={{
              backgroundImage: `url(${background_mobile})`,
              backgroundSize: 'cover',
            }}
          >
            <Container
              component="main"
              maxWidth="xs"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100vh',
              }}
            >
              <Stack
                spacing={3}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  alignItems: 'center',
                  mx: 6,
                }}
              >
                <img src={logo_polyrec} alt="Polyrec Logo" />
                <div>
                  <CircularProgress
                    // variant="determinate"
                    value={progress}
                    color="primary"
                    size={60}
                  />
                </div>
              </Stack>
              <Box
                sx={{
                  mt: 32,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                {/* <img src={logo_kb} alt="kb Logo" /> */}
                <Typography
                  fontFamily="Inter"
                  fontSize={10}
                  fontWeight={600}
                  color="#BBBBBB"
                  textAlign="center"
                  sx={{
                    textTransform: 'uppercase',
                  }}
                >
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: 400,
                    }}
                  >
                    Designed by
                  </span>{' '}
                  ABC,{' '}
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: 400,
                    }}
                  >
                    Developed by
                  </span>{' '}
                  KB, <br />
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: 400,
                    }}
                  >
                    all rights reserved
                  </span>{' '}
                  POLYREC
                </Typography>
              </Box>
            </Container>
          </Box>
        );
      case 'MainScreen':
        return (
          <Box
            component="div"
            sx={{
              // backgroundImage: `url(${Deco_logo})`,
              backgroundImage: `url(${background_mobile})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundColor: '#fff',
            }}
          >
            <Container
              maxWidth="xs"
              sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                p: 6,
              }}
            >
              <Box
                sx={{
                  width: 250,
                  height: 120,
                }}
              >
                <img src={logo_polyrec} alt="PolyRec Logo" />
              </Box>

              <Stack
                direction="column"
                spacing={3}
                sx={{
                  width: '100%',
                }}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit(e as any);
                  }
                }}
              >
                <TextField
                  required
                  id="outlined-required"
                  name="email"
                  type={'text'}
                  color="primary"
                  label="E-mail"
                  variant="outlined"
                  placeholder="E-mail"
                  onChange={handleChange}
                />
                {/* Password */}
                <FormControl variant="outlined" required>
                  <InputLabel htmlFor="outlined-start-adornment">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-start-adornment"
                    type={showPassword ? 'text' : 'password'}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          color="primary"
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                    name="password"
                    sx={{ backgroundColor: 'white' }}
                    fullWidth
                    onChange={handleChange}
                  />
                </FormControl>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  sx={{
                    height: 50,
                    '&:hover': {
                      // backgroundColor: theme.palette.secondary.dark,
                    },
                  }}
                  onClick={(e: any) => handleSubmit(e)}
                >
                  Login
                </Button>
              </Stack>
              <Box
                sx={{
                  mt: 32,
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: 1,
                }}
              >
                {/* <img src={logo_kb} alt="kb Logo" /> */}
                <Typography
                  fontFamily="Inter"
                  fontSize={10}
                  fontWeight={600}
                  color="#BBBBBB"
                  textAlign="center"
                  sx={{
                    textTransform: 'uppercase',
                  }}
                >
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: 400,
                    }}
                  >
                    Designed by
                  </span>{' '}
                  ABC,{' '}
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: 400,
                    }}
                  >
                    Developed by
                  </span>{' '}
                  KB, <br />
                  <span
                    style={{
                      fontFamily: 'Inter',
                      fontWeight: 400,
                    }}
                  >
                    all rights reserved
                  </span>{' '}
                  POLYREC
                </Typography>
              </Box>
            </Container>
          </Box>
        );
    }
  };
  return <div style={{ height: '100vh' }}>{renderView()}</div>;
};
